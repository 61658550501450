import axios from 'axios';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { QrReader } from 'react-qr-reader';
import { RegionJsonModel } from '../../../admin/core/models/region-model';

import CustomButton from '../../../core/components/customButton/customButton';
import showToast from '../../../core/components/Toast/Toast';
import SuccessMessages from '../../../core/messages/success-messages';
import { Authentication } from '../../../core/utils/authentication';
import { timeFormatter } from '../../../core/utils/time-formatter';
import { weekDaysNames } from '../../../core/utils/week-days';
import { getNextTripDate } from '../home/components/one-direction-form';
import './qr-scanner.scss';

const token = Authentication.getToken();
axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

const QrTripScanner = () => {
  const [regions, setTrips] = useState<RegionJsonModel[]>();

  const handleScan = async (data: any) => {
    if (data) {
      const { data: bookings } = await axios.get(
        // `https://192.168.1.2/passenger-trip/scan-qr?regionId=${data}`
        `https://newgoldenfly.com/passenger-trip/scan-qr?regionId=${data}`
      );
      setTrips(bookings);
    }
  };

  return (
    <div style={{ marginTop: '100px' }}>
      {!regions ? (
        <QrReader
          constraints={{ facingMode: { exact: 'environment' } }}
          onResult={handleScan}
          scanDelay={1000}
        />
      ) : (
        regions.map(region => (
          <div className="list-view">
            <Row>
              <h1 className="list-view__station-name">
                المنطقة: {region.name}
              </h1>
              {region.stations &&
                region.stations.map(station => (
                  <div style={{ marginTop: '2rem' }}>
                    <h2 style={{ paddingBottom: '0.5rem' }}>
                      المحطة: {station.name}
                    </h2>
                    {station.trips &&
                      station.trips.map(trip => (
                        <div>
                          <span className="list-view__leave-date">
                            تاريخ التحرك:
                            {trip.leaveDate.includes('0001')
                              ? `${
                                  weekDaysNames[
                                    new Date(trip.leaveDate).getDay()
                                  ]
                                } ${getNextTripDate(trip.leaveDate)}`
                              : trip.leaveDate}
                          </span>
                          {trip.tripsDetails &&
                            trip.tripsDetails.map(tripDetails => (
                              <div className="list-view__item">
                                <span className="list-view__leave-date">
                                  الوجهة:
                                  {tripDetails.destination === 'TO_UNIVERSITY'
                                    ? 'إلي الجامعة'
                                    : 'إلي  المحطة'}
                                </span>
                                <span className="list-view__leave-date">
                                  وقت التحرك:
                                  {timeFormatter(tripDetails.leaveTime)}
                                </span>
                                <CustomButton
                                  text="تأكيد الحجز"
                                  color="yellow"
                                  onClick={async () => {
                                    try {
                                      await axios.patch(
                                        `https://newgoldenfly.com/passenger-trip/scan-qr/${
                                          tripDetails!.passengerTrip!.id
                                        }`
                                        // `https://newgoldenfly.com/passenger-trip/scan-qr/${
                                        //   tripDetails!.passengerTrip!.id
                                        // }`
                                      );

                                      showToast({
                                        msg: SuccessMessages.BOOKED_SUCCESSFULLY,
                                        type: 'success',
                                      });
                                    } catch (error) {
                                      showToast({
                                        msg: JSON.stringify(error),
                                        type: 'error',
                                      });
                                    }
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                ))}
            </Row>
          </div>
        ))
      )}
    </div>
  );
};
export default QrTripScanner;
